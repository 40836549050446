import { Component, OnInit, ViewChild, ElementRef, Renderer2, AfterViewInit } from "@angular/core";

import { RouteInfo } from './sidebar.metadata';
import { ROUTES } from './sidebar-routes.config';
import { Router, ActivatedRoute } from "@angular/router";
import { TranslateService } from '@ngx-translate/core';
import { customAnimations } from "../animations/custom-animations";
import { ConfigService } from '../services/config.service';
import { AuthService } from '../auth/auth.service';
import { FirebaseHelperService } from '../services/firebase-helper.service';
import { PSPName } from '../services/general';
import { Subscription } from 'rxjs';

@Component({
  selector: "app-sidebar",
  templateUrl: "./sidebar.component.html",
  animations: customAnimations
})
export class SidebarComponent implements OnInit, AfterViewInit {

  @ViewChild('toggleIcon', {static: false} ) toggleIcon: ElementRef;
  public menuItems: any[];
  depth: number;
  activeTitle: string;
  activeTitles: string[] = [];
  expanded: boolean;
  nav_collapsed_open = false;
  logoUrl = 'assets/img/logo_sarthi.png';
  public config: any = {};
  pspNames: PSPName[]=[];
  schedListSubs: Subscription=null;

  clcked() {
    console.log("clcked(): ", this.expanded);
  }
  constructor(
    private elementRef: ElementRef,
    private renderer: Renderer2,
    private router: Router,
    private route: ActivatedRoute,
    public translate: TranslateService,
    private configService: ConfigService,private auth: AuthService,public afs: FirebaseHelperService
  ) {
    if (this.depth === undefined) {
      this.depth = 0;
      this.expanded = true;
    }
    auth.afAuth.authState.subscribe(user=>{
      // console.log(user.uid);
      if(user!=null) {
        this.createSidebarEntries();
        // this.schedListSubs = this.afs.getCustPSPSchedList(user.uid).subscribe(data=>{
        //   this.pspNames = PSPName.fromJsonArray(data);
        //   // console.log("this.pspNames: ", this.pspNames);
        //   this.createSidebarEntries();
        // });
      }
    });
  }

  ngOnDestroy() {
    if(this.schedListSubs!=null) {
      this.schedListSubs.unsubscribe();
    }
  }

  ngOnInit() {
    this.config = this.configService.templateConf;
    // this.menuItems = ROUTES;

    // console.log(this.config);


    if (this.config.layout.sidebar.backgroundColor === 'white') {
      this.logoUrl = 'assets/img/sarthi_original.png';
    }
    else {
      this.logoUrl = 'assets/img/sarthi_original.png';
    }
  }

  createSidebarEntries() {
    var sidebarExams: RouteInfo[] = [];
    this.afs.exams.forEach(e=>{
      var subsub0: RouteInfo=new RouteInfo;
      subsub0.path = '/examstudyplan/'+e["id"];
      subsub0.title = e["name"]; 
      subsub0.icon = 'ft-check-circle';
      subsub0.class = '';
      subsub0.badge = '';
      subsub0.badgeClass = '';
      subsub0.isExternalLink = false;
      subsub0.submenu = [];
      if(e["id"] == "USMLE1") {
        sidebarExams.push(subsub0);
      }
    });

    // var subsub1: RouteInfo=new RouteInfo;
    // subsub1.path = '/addnewpsp';
    // subsub1.title = 'Ready-To-Import'; 
    // subsub1.icon = 'ft-copy';
    // subsub1.class = '';
    // subsub1.badge = '';
    // subsub1.badgeClass = '';
    // subsub1.isExternalLink = false;
    // subsub1.submenu = [];

    // var subsub2: RouteInfo=new RouteInfo;
    // subsub2.path = '/createcustom';
    // subsub2.title = 'Create Custom'; 
    // subsub2.icon = 'ft-box';
    // subsub2.class = '';
    // subsub2.badge = '';
    // subsub2.badgeClass = '';
    // subsub2.isExternalLink = false;
    // subsub2.submenu = [];

    // var newPlan: RouteInfo=new RouteInfo;
    // newPlan.path = '';
    // newPlan.title = 'New Plan'; 
    // newPlan.icon = 'ft-plus';
    // newPlan.class = 'has-sub';
    // newPlan.badge = '';
    // newPlan.badgeClass = '';
    // newPlan.isExternalLink = false;
    // newPlan.submenu = sidebarExams;//[subsub1, subsub2];

    // var plansMenu: RouteInfo[]=[];
    // this.pspNames.forEach(p=>{
    //   var m: RouteInfo=new RouteInfo;
    //   m.badge = '';
    //   m.badgeClass = '';
    //   m.class = '';
    //   m.icon = 'ft-check-circle';
    //   m.isExternalLink = false;
    //   m.path = '/planview/'+p.id;
    //   m.submenu = [];
    //   m.title = p.name;
    //   plansMenu.push(m);
    // });
    // plansMenu.push(newPlan);

    var leftMenu: RouteInfo[]=[];
    var planner: RouteInfo=new RouteInfo;
    planner.path = '';
    planner.title = 'Study Plans';
    planner.icon = 'ft-life-buoy';
    planner.class = 'has-sub';
    planner.badge = '';
    planner.badgeClass = '';
    planner.isExternalLink = false;
    planner.submenu = sidebarExams;//plansMenu;

    var cal: RouteInfo=new RouteInfo;
    cal.path = '/mycalendar';
    cal.title = 'My Calendar';
    cal.icon = 'ft-calendar';
    cal.class = '';
    cal.badge = '';
    cal.badgeClass = '';
    cal.isExternalLink = false;
    cal.submenu = [];

    var fc: RouteInfo=new RouteInfo;
    fc.path = '/flashcards';
    fc.title = 'Flash Cards';
    fc.icon = 'ft-layers';
    fc.class = '';
    fc.badge = '';
    fc.badgeClass = '';
    fc.isExternalLink = false;
    fc.submenu = [];

    var rs: RouteInfo=new RouteInfo;
    rs.path = 'https://www.usmlesarthi.com/researchtomatch.html';
    rs.title = 'Research';
    rs.icon = 'ft-award';
    rs.class = '';
    rs.badge = '';
    rs.badgeClass = '';
    rs.isExternalLink = true;
    rs.submenu = [];

    var about: RouteInfo=new RouteInfo;
    about.path = 'https://www.usmlesarthi.com/aboutusmlesarthi.html';
    about.title = 'About USMLEsarthi';
    about.icon = 'ft-alert-circle';
    about.class = '';
    about.badge = '';
    about.badgeClass = '';
    about.isExternalLink = true;
    about.submenu = [];

    var rotations: RouteInfo=new RouteInfo;
    rotations.path = 'https://www.usmlesarthi.com/clinicalrotations.html';
    rotations.title = 'Clinical Rotations';
    rotations.icon = 'ft-server';
    rotations.class = '';
    rotations.badge = '';
    rotations.badgeClass = '';
    rotations.isExternalLink = true;
    rotations.submenu = [];

    var aboutplanner: RouteInfo=new RouteInfo;
    aboutplanner.path = 'https://www.usmleplanner.com';
    aboutplanner.title = 'About Planner';
    aboutplanner.icon = 'ft-alert-circle';
    aboutplanner.class = '';
    aboutplanner.badge = '';
    aboutplanner.badgeClass = '';
    aboutplanner.isExternalLink = true;
    aboutplanner.submenu = [];

    // leftMenu.push(cal);
    leftMenu.push(planner);
    // leftMenu.push(fc);
    leftMenu.push(aboutplanner);
    leftMenu.push(rs);
    leftMenu.push(rotations);
    leftMenu.push(about);

    this.menuItems = leftMenu;
    // console.log(leftMenu);
  }

  ngAfterViewInit() {

    setTimeout(() => {
      if (this.config.layout.sidebar.collapsed != undefined) {
        if (this.config.layout.sidebar.collapsed === true) {
          this.expanded = false;
          this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = true;
        }
        else if (this.config.layout.sidebar.collapsed === false) {
          this.expanded = true;
          this.renderer.removeClass(this.toggleIcon.nativeElement, 'ft-toggle-left');
          this.renderer.addClass(this.toggleIcon.nativeElement, 'ft-toggle-right');
          this.nav_collapsed_open = false;
        }
      }
    }, 0);


  }

  toggleSlideInOut() {
    // console.log("toggleSlideInOut");
    this.expanded = !this.expanded;
  }

  handleToggle(titles) {
    // console.log(titles);
    this.activeTitles = titles;
  }

  // NGX Wizard - skip url change
  ngxWizardFunction(path: string) {
    if (path.indexOf("forms/ngx") !== -1)
      this.router.navigate(["forms/ngx/wizard"], { skipLocationChange: false });
  }
}
