import { Component, Output, EventEmitter, OnInit, AfterViewInit } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import * as hopscotch from 'hopscotch';
import { LayoutService } from '../services/layout.service';
import { ConfigService } from '../services/config.service';
import { AuthService } from '../auth/auth.service';
import { HighlightService } from '../services/highlight.service';
import { Router } from '@angular/router';
import { AngularFirestore } from '@angular/fire/firestore';
import { FirebaseHelperService } from '../services/firebase-helper.service';
import { User } from 'firebase';

@Component({
  selector: "app-navbar",
  templateUrl: "./navbar.component.html",
  styleUrls: ["./navbar.component.scss"]
})
export class NavbarComponent implements OnInit, AfterViewInit {
  currentLang = "en";
  toggleClass = "ft-maximize";
  placement = "bottom-right";
  public isCollapsed = true;
  @Output()
  toggleHideSidebar = new EventEmitter<Object>();
  highlighted: boolean = false;
  user: User=undefined;

  public config: any = {};

  constructor(public fhs: FirebaseHelperService, public translate: TranslateService, private layoutService: LayoutService, public authService: AuthService,
        private configService:ConfigService, private router: Router, private highlightService: HighlightService, private afs: AngularFirestore) {
          
    const browserLang: string = translate.getBrowserLang();
    translate.use(browserLang.match(/en|es|pt|de/) ? browserLang : "en");

    authService.afAuth.authState.subscribe(u=>{
      if(u!=undefined&&u.uid!=undefined) {
        this.user = u;
        // afs.collection('customers').doc(u.uid).collection('subscriptions', ref=>ref.where('status', 'in', ['trialing', 'active']))
        // .snapshotChanges().subscribe(snapshot=>{
        //   const doc = snapshot[0];
        //   snapshot.forEach(s=>{
        //     if(s.payload.doc.get("current_period_end")!=undefined) {
        //       console.log(s.payload.doc.get("current_period_end"));
        //       console.log(s.payload.doc.get("stripeLink"));
        //     }
        //   });
        // });
      }
    });
  }

  ngAfterViewChecked() {
    this.highlightService.highlightAll();
  }

  ngOnInit() {
    this.config = this.configService.templateConf;
  }
  ngAfterViewInit() {
    if(this.config.layout.dir) {
      const dir = this.config.layout.dir;
        if (dir === "rtl") {
          this.placement = "bottom-left";
        } else if (dir === "ltr") {
          this.placement = "bottom-right";
        }
    }
  }


  ChangeLanguage(language: string) {
    this.translate.use(language);
  }

  ToggleClass() {
    if (this.toggleClass === "ft-maximize") {
      this.toggleClass = "ft-minimize";
    } else {
      this.toggleClass = "ft-maximize";
    }
  }

  toggleNotificationSidebar() {
    this.layoutService.emitChange(true);
  }

  annul() {
    if(this.user!=undefined) {
      this.fhs.setClaimGenericDate(this.user.email, "trial", undefined);
    }
  }

  expire() {
    if(this.user!=undefined) {
      this.fhs.setClaimGenericDate(this.user.email, "trial", this.fhs.addDays(new Date(), -1));
    }
  }

  toggleSidebar() {
    const appSidebar = document.getElementsByClassName("app-sidebar")[0];
    if (appSidebar.classList.contains("hide-sidebar")) {
      this.toggleHideSidebar.emit(false);
    } else {
      this.toggleHideSidebar.emit(true);
    }
  }

  startTour() {
    // Destroy running tour
    hopscotch.endTour(true);
    // Initialize new tour 
    hopscotch.startTour(this.tourSteps());
  }
  showAssumptions() {
    // console.log("show assumptions called");
    this.router.navigate(["/profile/assumptions"], { skipLocationChange: false });    
  }
  ngOnDestroy() {
    // Destroy running tour
    hopscotch.endTour(true);
  }
  tourSteps(): any {
    return {
      id: 'demo-tour',
      showPrevButton: true,
      steps: [
        {
          title: "Toggle Sidebar",
          content: "Increase work area by hiding sidebar. It will automatically expand when mouse is over the sidebar",
          target: "sidebarToggle",
          placement: "bottom"
        },
        {
          title: "Full Screen",
          content: "View this page in full screen mode.",
          target: "navbar-fullscreen",
          placement: "left"
        },
        {
          title: "User Profile & Logout",
          content: "Use this to enter your user profile and for logging out.",
          target: "dropdownBasic3",
          placement: "left"
        },
        {
          title: "Feedback",
          content: "Please send us feedback on the planner using this side drawer.",
          target: "customizer-toggle-icon",
          placement: "left"
        },
        {
          title: "Study Task",
          content: "You study tasks are listed inside this tab. They are based on what's due today, overdue and what's listed for future dates",
          target: "tab-1",
          placement: "top"
        },
        {
          title: "Study Notes",
          content: "See all your notes at a single glance. This can help in quick revision anytime.",
          target: "notes",
          placement: "top"
        },
        {
          title: "Progress Charts",
          content: "See your progress. Your ratings are addedd cumulatively and displayed as a comparison chart. Also, your punctuality in completion is tracked as an incremental chart. But wait, if you're late then you do score a negative point.",
          target: "tab-2",
          placement: "bottom"
        },
        {
          title: "Setup",
          content: "Setup your study plan here. Select a plan, provide start date, designate weekly days and hours, inspect projected end date and refine and then save. You are in control of your studies now.",
          target: "tab-3",
          placement: "left"
        },
      ]
    }
  }
}
