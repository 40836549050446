// The file contents for the current environment will overwrite these during build.
// The build system defaults to the dev environment which uses `environment.ts`, but if you do
// `ng build --env=prod` then `environment.prod.ts` will be used instead.
// The list of which env maps to which file can be found in `.angular-cli.json`.

export const environment = {
  production: false,
  publishableKey: 'pk_test_oUUoqLlOpa2gz7yL4jkTju6J',
  config: {
    apiKey: "AIzaSyCtsu8d5m10sHZM4FsAAdz8wzAfwJGb_Mw",
    authDomain: "usmles.firebaseapp.com",
    databaseURL: "https://usmles.firebaseio.com",
    projectId: "usmles",
    storageBucket: "usmles.appspot.com",
    messagingSenderId: "467549083017",
    appId: "1:467549083017:web:142b5e348af81d62ca3d58",
    measurementId: "G-PPJM10VCMG"
  }
};
