import {
	trigger,
	animate,
	style,
	transition,
	state
} from '@angular/animations';


export const customAnimations = [	    
    trigger('slideInOut', [
      state('1', style({ height: '*' })),
      state('0', style({ height: '0px' })),
      transition('1 <=> 0', animate(200))
    ]),
    trigger(
      'enterAnimation', [
        transition(':enter', [
          style({transform: 'translateX(100%)', opacity: 0}),
          animate('500ms', style({transform: 'translateX(0)', opacity: 1, 'overflow-x': 'hidden'}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('500ms', style({transform: 'translateX(100%)', opacity: 0}))
        ])
      ]
    )    
]