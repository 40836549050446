// Sidebar route metadata
export class RouteInfo {
    path: string;
    title: string;
    icon: string;
    class: string;
    badge: string;
    badgeClass: string;
    isExternalLink: boolean;
    submenu : RouteInfo[];

    // constructor(
    //     public path: string, 
    //     public title: string, 
    //     public icon: string, 
    //     public class: string, 
    //     public badge: string, 
    //     public badgeClass: string,
    //     public isExternalLink: boolean,
    //     public submenu : RouteInfo[]){}
        
}
