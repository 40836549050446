import { Component, OnInit, Input, Output, EventEmitter } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { FirebaseHelperService } from 'app/shared/services/firebase-helper.service';
import { FeedbackElement } from 'app/shared/services/general';
import { take } from 'rxjs/operators';
import { ResourceCompletion } from 'app/shared/services/localBusinessClasses';

@Component({
  selector: 'render-feedback',
  templateUrl: './render-feedback.component.html',
  styleUrls: ['./render-feedback.component.scss']
})
export class RenderFeedbackComponent implements OnInit {
  @Input() rid: string;
  @Input() uid: string;
  @Input() rtid: string=null;
  @Input() type: string;
  @Input() examID: string;
  @Input() revision: number;
  @Input() durationHours: number;
  @Output() public completionSubmitted = new EventEmitter<any>();
  @Output() public feedbackSubmitted = new EventEmitter<any>();
  fbe: FeedbackElement[]=[];
  retObject={};
  multiValueMap: Map<number,string[]>=new Map<number,string[]>();
  actualDuration: number=null;

  constructor(private afs: FirebaseHelperService, private toastr: ToastrService) { 
    // console.log("constructor called");
  }

  ngOnInit() {
    // console.log("ngOnInit");
    if(this.rtid!=null) {
      console.log("2. ", this.rtid);
      this.afs.getFeedbackElement(this.rtid).pipe(take(1)).subscribe(f=>{
        console.log(f);
        this.fbe=FeedbackElement.fromJsonArray(f);
        console.log(this.fbe);
      });
    } else {
      this.afs.getGeneralFeedbackElementsList().pipe(take(1)).subscribe(f=>{
        // console.log(f);
        this.fbe=FeedbackElement.fromJsonArray(f);
        // console.log(this.fbe);
        // this.afs.getAllFeedbackElements().pipe(take(1)).subscribe(a=>{
        //   console.log("got it");
        // });
      });
    }
  }

  getQualifierArray(index) {
    if(this.fbe!=null&&this.fbe.length>=index) {
      let f=this.fbe[index];
      if(f.commaSeparatedQualifiers!=null&&f.commaSeparatedQualifiers.trim()!="") {
        let str=f.commaSeparatedQualifiers.trim();
        let ar = str.split(",");
        ar.forEach(a=>{
          a=a.trim();
        });
        return ar;
      }
    }
    return [];
  }

  radioEvent(index, seq) {
    // console.log(index,seq);
    this.retObject[this.fbe[index].label]=this.getQualifierArray(index)[seq];
  }
  checkedEvent(index, seq,e) {
    if (e.target.checked) {
      let existing=this.multiValueMap.get(index);
      if(existing==undefined) {
        existing=[this.getQualifierArray(index)[seq]];
      } else {
        existing.push(this.getQualifierArray(index)[seq]);
      }
      this.multiValueMap.set(index, existing);
      // this.product.badges.push(id);
    }  else {
      this.multiValueMap.get(index).splice(this.multiValueMap.get(index).indexOf(this.getQualifierArray(index)[seq]), 1);
      let exist=this.multiValueMap.get(index);
      this.multiValueMap.set(index, exist);
      // this.product.badges.splice(this.product.badges.indexOf(id), 1);
    }    
    this.retObject[this.fbe[index].label]=this.multiValueMap.get(index).toString();
    // console.log(index,seq,e.target.checked);
  }

  startRating(index,value) {
    this.retObject[this.fbe[index].label]=value;
    // console.log(index, value);
  }

  freeForm(index, $event) {
    this.retObject[this.fbe[index].label]=$event.target.value;
    // console.log(index, $event.target.value);
  }

  numberInput(index, $event) {
    this.retObject[this.fbe[index].label]=+$event.target.value;
    // console.log(index, $event.target.value);
  }

  markDone() {
    var canSubmit=true;
    if(this.rtid!=null&&this.actualDuration==undefined) {
      this.toastr.warning("Please specify time taken to finish this study item");
      return;
    }
    this.fbe.forEach((f,index)=>{
      if([1,2].includes(f.feedbackType)==false) {//freeform
        let val = this.retObject[f.label];
        if(val==undefined) {
          // console.log(f);
          this.toastr.warning("You haven't filled out "+f.label+" field");
          canSubmit=false;
        }
      } else {
        let val = this.retObject[f.label];
        if(val==undefined) {
          this.retObject[f.label]="";
        } else {
          // console.log("val: ", val);
          this.retObject[f.label]=val.trim();
        }
      }
    });
    if(canSubmit==true&&this.rtid==null) {
      this.feedbackSubmitted.emit({'submitted': this.retObject});
    } else {
      if(canSubmit==true) {
        var c: Boolean=true;
        if(this.type=='od') {
          c=false;
        }
        // console.log(JSON.stringify(this.revision));
        let rc=new ResourceCompletion(this.afs.getCreateID(), this.rid, this.afs.formatDate(new Date()), true, c, JSON.stringify(this.retObject), 
              this.revision, this.actualDuration, +this.durationHours);
        this.afs.saveResourceCompletionStatus(this.uid, this.examID, rc).then(()=>{
          this.toastr.success("Marked Completed", "Good job");
          this.completionSubmitted.emit({'submitted':true});
        }) 
      }
    }
  }

  cancel() {
    this.toastr.warning("Cancelled");
    this.completionSubmitted.emit({'cancelled':true});
    this.feedbackSubmitted.emit({'cancelled': true});
  }

}
