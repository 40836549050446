import { PSPDisplayRow } from '../services/general';

export class PartialRemaining {
  row: PSPDisplayRow;
  remainingDuration: number;
  constructor(row, remain) {
    this.row=row;
    this.remainingDuration=remain;
  }
}

export class CustomerPlanSetup {
  constructor(
      public id: string,
      public planID: string,
      public daysChecked: Boolean[],
      public hoursForDay: Number[],
      public startDate: string,
      public subjectIDs: string[],
      public tagIDs: string[],
      public resourceTypeIDs: string[],
      public complexities: number[],
  ) {}

  static fromJson({id, planID, daysChecked, hoursForDay, startDate, subjectIDs, tagIDs, resourceTypeIDs, complexities}) {
      return new CustomerPlanSetup(id, planID, daysChecked, hoursForDay, startDate, subjectIDs, tagIDs, resourceTypeIDs, complexities);
  }

  // static fromJsonArray(json : any[]) : CustomerPlanSetup[] {
  //     return json.map(CustomerPlanSetup.fromJson);
  // }

  toJson(): any {
      return Object.assign({id: this.id }, {planID: this.planID }, {daysChecked: this.daysChecked }, {hoursForDay: this.hoursForDay },
              {startDate: this.startDate}, {subjectIDs: this.subjectIDs}, {tagIDs: this.tagIDs}, {resourceTypeIDs: this.resourceTypeIDs},
                {complexities: this.complexities});
  }
}

export class ResourceCompletion {
  constructor(
    public id: string,
    public resourceID: string,
    public date: String,
    public completed: Boolean,
    public onTime: Boolean,
    public rtSpecificPayload: string,
    public revision: number,
    public timeSpent: number,
    public assignedDuration: number
  ) {}

  static fromJson({id, resourceID, date, completed, onTime, rtSpecificPayload, revision, timeSpent, assignedDuration}) {
    return new ResourceCompletion(id, resourceID, date, completed, onTime, rtSpecificPayload, revision, timeSpent, assignedDuration);
  }

  static fromJsonArray(json : any[]) : ResourceCompletion[] {
      return json.map(ResourceCompletion.fromJson);
  }

  toJson(): any {
    return Object.assign({id: this.id}, {resourceID: this.resourceID}, {date: this.date}, {completed: this.completed}, 
        {onTime: this.onTime}, {rtSpecificPayload: this.rtSpecificPayload}, 
          {revision: this.revision}, {timeSpent: this.timeSpent}, {assignedDuration: this.assignedDuration});
  }
}

export class StudyNotes {
  constructor(
    public rid: string,
    public uid: String,
    public dated: string,
    public text: string,
    public rtitle: string
  ) {}

  static fromJson({rid, uid, dated, text, rtitle}) {
    return new StudyNotes(rid, uid, dated, text, rtitle);
  }

  static fromJsonArray(json : any[]) : StudyNotes[] {
      return json.map(StudyNotes.fromJson);
  }

  toJson(): any {
    return Object.assign({rid: this.rid}, {uid: this.uid}, {dated: this.dated}, {text: this.text}, {rtitle: this.rtitle});
  }
}

export class SharedStudyNotes {
  constructor(
    public id: string,
    public rid: string,
    public uid: String,
    public dated: string,
    public text: string,
    public rtitle: string
  ) {}

  static fromJson({id, rid, uid, dated, text, rtitle}) {
    return new SharedStudyNotes(id, rid, uid, dated, text, rtitle);
  }

  static fromJsonArray(json : any[]) : SharedStudyNotes[] {
      return json.map(SharedStudyNotes.fromJson);
  }

  toJson(): any {
    return Object.assign({id: this.id}, {rid: this.rid}, {uid: this.uid}, {dated: this.dated}, {text: this.text}, {rtitle: this.rtitle});
  }
}

export class MailInfo {
  constructor(
    public subject: string,
    public message: string,
    public to: string
  ){}
  toJson(): any {
    return Object.assign({to: this.to}, {message: {
      subject: this.subject,
      text: this.message 
    }});
  }  
}


type ResourceVersionTuple = [string, number];
export class ResourceRevisionTupleMap {
  private map = new Map<string, ResourceCompletion>();

  set(key: ResourceVersionTuple, value: ResourceCompletion): this {
      this.map.set(JSON.stringify(key), value);
      return this;
  }

  get(key: ResourceVersionTuple): ResourceCompletion | undefined {
      return this.map.get(JSON.stringify(key));
  }

  clear() {
      this.map.clear();
  }

  delete(key: ResourceVersionTuple): boolean {
      return this.map.delete(JSON.stringify(key));
  }

  has(key: ResourceVersionTuple): boolean {
      return this.map.has(JSON.stringify(key));
  }

  get size() {
      return this.map.size;
  }

  forEach(callbackfn: (value: ResourceCompletion, key: ResourceVersionTuple, map: Map<ResourceVersionTuple, ResourceCompletion>) => void, thisArg?: any): void {
      this.map.forEach((value, key) => {
          callbackfn.call(thisArg, value, JSON.parse(key), this);
      });
  }
}
