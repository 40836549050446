import {Component,OnInit,Output,EventEmitter,AfterViewInit,OnDestroy,ViewChild,ElementRef,Renderer2} from "@angular/core";
import { LayoutService } from "../services/layout.service";
import { ConfigService } from "../services/config.service";
import { Subscription } from "rxjs";
import { FormControl, Validators } from '@angular/forms';
import { FirebaseHelperService } from '../services/firebase-helper.service';
import { ToastrService } from 'ngx-toastr';
import { AuthService } from '../auth/auth.service';

@Component({
  selector: "app-customizer",
  templateUrl: "./customizer.component.html",
  styleUrls: ["./customizer.component.scss"]
})
export class CustomizerComponent implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("customizer", {static: false}) customizer: ElementRef;

  options = {
    direction: "ltr",
    bgColor: "black",
    transparentColor: "",
    bgImage: "assets/img/sidebar-bg/01.jpg",
    bgImageDisplay: true,
    compactMenu: false,
    sidebarSize: "sidebar-md",
    layout: "Light"
  };
  size = "sidebar-md";
  isOpen = true;
  public config: any = {};
  layoutSub: Subscription;
  sub: Subscription=null
  isBgImageDisplay = true;
  selectedBgColor: string = "black";
  selectedBgImage: string = "assets/img/sidebar-bg/01.jpg";
  selectedTLBgColor: string = "";
  selectedTLBgImage: string = "";

  currentRate = 8;
  currentRating = 6;
  selected = 0;
  hovered = 0;
  readonly = false;
  decimalCurrentRate = 3.14;
  ctrl = new FormControl(null, Validators.required);
  suggestion="";
  uid="";
  factors=["Usefulness", "Reliability", "Ease-of-Use", "Relevance"];
  values=[];


  constructor(
    private elRef: ElementRef,
    private renderer: Renderer2,
    private layoutService: LayoutService,
    private configService: ConfigService, public afs: FirebaseHelperService, private auth: AuthService, private toastr: ToastrService
  ) {
    this.factors.forEach(f=>{
      this.values.push(0);
    });
    this.sub=auth.afAuth.authState.subscribe(s=>{
      if(s!=null) {
        this.uid=s.uid;
      }
    });
    this.layoutSub = layoutService.customizerChangeEmitted$.subscribe(
      options => {
        if (options) {
          if (options.bgColor) {
            this.selectedBgColor = options.bgColor;
            this.selectedBgImage = options.bgImage;
          }
        }
      }
    );
  }

  @Output() directionEvent = new EventEmitter<Object>();

  ngOnInit() {
    this.config = this.configService.templateConf;
    this.isOpen = this.config.layout.customizer.hidden;

    if (this.config.layout.sidebar.size) {
      this.options.sidebarSize = this.config.layout.sidebar.size;
      this.size = this.config.layout.sidebar.size;
    }
  }

  ngAfterViewInit() {
    setTimeout(() => {
      if (this.config.layout.dir) {
        this.options.direction = this.config.layout.dir;
      }

      if (this.config.layout.variant) {
        this.options.layout = this.config.layout.variant;
      }
      if (this.config.layout.sidebar.collapsed != undefined) {
        this.options.compactMenu = this.config.layout.sidebar.collapsed;
      }

      if (
        this.config.layout.sidebar.backgroundColor &&
        this.config.layout.sidebar.backgroundColor != ""
      ) {
        this.options.bgColor = this.config.layout.sidebar.backgroundColor;
        this.selectedBgColor = this.config.layout.sidebar.backgroundColor;
      } else {
        this.options.bgColor = "black";
        this.selectedBgColor = "black";
      }

      if (this.config.layout.sidebar.backgroundImage != undefined) {
        this.options.bgImageDisplay = this.config.layout.sidebar.backgroundImage;
        this.isBgImageDisplay = this.config.layout.sidebar.backgroundImage;
      }

      if (this.config.layout.sidebar.backgroundImageURL) {
        this.options.bgImage = this.config.layout.sidebar.backgroundImageURL;
        this.selectedBgImage = this.config.layout.sidebar.backgroundImageURL;
      }

      if (this.options.layout === "Transparent") {
        this.options.bgColor = "black";
        this.selectedBgColor = "black";
        this.options.bgImageDisplay = false;
        this.selectedTLBgColor = "";
        this.selectedBgImage = "";
        this.options.bgImage = "";
        this.isBgImageDisplay = false;

        if (this.config.layout.sidebar.backgroundColor) {
          if (this.config.layout.sidebar.backgroundColor === "bg-glass-1") {
            this.selectedTLBgImage = "assets/img/gallery/bg-glass-1.jpg";
            this.options.transparentColor = "bg-glass-1";
          } else if (
            this.config.layout.sidebar.backgroundColor === "bg-glass-2"
          ) {
            this.selectedTLBgImage = "assets/img/gallery/bg-glass-2.jpg";
            this.options.transparentColor = "bg-glass-2";
          } else if (
            this.config.layout.sidebar.backgroundColor === "bg-glass-3"
          ) {
            this.selectedTLBgImage = "assets/img/gallery/bg-glass-3.jpg";
            this.options.transparentColor = "bg-glass-3";
          } else if (
            this.config.layout.sidebar.backgroundColor === "bg-glass-4"
          ) {
            this.selectedTLBgImage = "assets/img/gallery/bg-glass-4.jpg";
            this.options.transparentColor = "bg-glass-4";
          } else {
            this.options.transparentColor = this.config.layout.sidebar.backgroundColor;
            this.selectedTLBgColor = this.config.layout.sidebar.backgroundColor;
          }
        } else {
          this.options.bgColor = "black";
          this.selectedBgColor = "black";
          this.options.bgImageDisplay = false;
          this.selectedBgColor = "";
          this.selectedTLBgColor = "";
          this.selectedTLBgImage = "assets/img/gallery/bg-glass-1.jpg";
          this.options.transparentColor = "bg-glass-1";
        }
      }
    }, 0);
  }
  ngOnDestroy() {
    if (this.layoutSub) {
      this.layoutSub.unsubscribe();
    }
    if (this.sub) {
      this.sub.unsubscribe();
      this.sub=null;
    }

  }

  sendOptions() {
    this.directionEvent.emit(this.options);
    this.layoutService.emitChange(this.options);
  }

  bgImageDisplay(e) {
    if (e.target.checked) {
      this.options.bgImageDisplay = true;
      this.isBgImageDisplay = true;
    } else {
      this.options.bgImageDisplay = false;
      this.isBgImageDisplay = false;
    }
    //emit event to FUll Layout
    this.layoutService.emitCustomizerChange(this.options);
  }

  handleFeedbackCompletedEvent($event) {
    // console.log($event["submitted"]);
    let val = $event["submitted"];
    if(val!=undefined) {
      this.afs.reportFeedback(this.uid, val).then(()=>{
        // console.log("saved");
        this.clear();
        this.toggleCustomizer();
        this.toastr.success('Thank you for helping us!', 'We really appreciate your feedback!');
        this.afs.saveEMailingRequestToSudeepAndPawan("feedback reported", JSON.stringify(val));
      });
    } else if($event["cancelled"]==true) {
      this.toggleCustomizer();
      // this.toastr.warning('Cancelled!');
    }
  }

  submit() {
    console.log(this.values);
    var obj: any = {};
    this.factors.forEach((f, index)=>{
      obj[f.replace(/\s/g, "")]=this.values[index];
      // obj.push({f: this.values[index]})
    });
    obj["dated"]=this.afs.formatDate(new Date());
    obj["uid"]=this.uid;
    obj["comments"]=this.suggestion;
    console.log(JSON.stringify(obj));
    if(this.anyStarrFilled()==true) {
      // console.log(this.ctrl.value, this.suggestion);
      this.afs.reportFeedback(this.uid, obj).then(()=>{
        // console.log("saved");
        this.clear();
        this.toggleCustomizer();
        this.toastr.success('Thank you for helping us!', 'We really appreciate your feedback!');
        this.afs.saveEMailingRequestToSudeepAndPawan("feedback reported", JSON.stringify(obj));
      });
    } else {
      // console.log(this.ctrl.value, this.suggestion);
      this.toastr.warning('Please enter a star rating and comments!');
    }
  }

  anyStarrFilled() {
    var bRetVal=false; 
    this.values.forEach(v=>{
      if(v>0) {
        bRetVal=true;
      }
    });
    return bRetVal;
  }

  clear() {
    this.ctrl.setValue(null);
    for(var i=0;i<this.values.length;i++) {
      this.values[i]=0;
    }
    this.suggestion="";
  }

  toggleCompactMenu(e) {
    if (e.target.checked) {
      this.options.compactMenu = true;
    } else {
      this.options.compactMenu = false;
    }
    //emit event to FUll Layout
    this.layoutService.emitCustomizerChange(this.options);
  }

  changeSidebarWidth(value) {
    this.options.sidebarSize = value;
    this.size = value;
    //emit event to FUll Layout
    this.layoutService.emitCustomizerChange(this.options);
  }

  onLightLayout() {
    this.options.layout = "Light";
    this.options.bgColor = "man-of-steel";
    this.selectedBgColor = "man-of-steel";
    if (this.isBgImageDisplay) {
      this.options.bgImageDisplay = true;
    }

    //emit event to FUll Layout
    this.layoutService.emitCustomizerChange(this.options);
  }

  onDarkLayout() {
    this.options.layout = "Dark";
    this.options.bgColor = "black";
    this.selectedBgColor = "black";
    if (this.isBgImageDisplay) {
      this.options.bgImageDisplay = true;
    }
    //emit event to FUll Layout
    this.layoutService.emitCustomizerChange(this.options);
  }

  onTransparentLayout() {
    this.options.layout = "Transparent";
    this.options.bgColor = "black";
    this.selectedBgColor = "black";
    this.options.bgImageDisplay = false;
    this.selectedBgColor = "";
    this.selectedTLBgColor = "";
    this.selectedTLBgImage = "assets/img/gallery/bg-glass-1.jpg";
    this.options.transparentColor = "bg-glass-1";

    //emit event to FUll Layout
    this.layoutService.emitCustomizerChange(this.options);
  }

  toggleCustomizer() {
    if (this.isOpen) {
      this.renderer.removeClass(this.customizer.nativeElement, "open");
      this.isOpen = false;
    } else {
      this.renderer.addClass(this.customizer.nativeElement, "open");
      this.isOpen = true;
    }
  }

  closeCustomizer() {
    this.renderer.removeClass(this.customizer.nativeElement, "open");
    this.isOpen = false;
  }

  changeSidebarBgColor(color) {
    this.selectedBgColor = color;
    this.selectedTLBgColor = "";
    this.options.bgColor = color;
    if (this.isBgImageDisplay) {
      this.options.bgImageDisplay = true;
    }
    //emit event to FUll Layout
    this.layoutService.emitCustomizerChange(this.options);
  }

  changeSidebarBgImage(url) {
    this.selectedBgImage = url;
    this.selectedTLBgImage = "";

    //emit event to FUll Layout
    this.options.bgImage = url;
    if (this.isBgImageDisplay) {
      this.options.bgImageDisplay = true;
    }
    //emit event to FUll Layout
    this.layoutService.emitCustomizerChange(this.options);
  }

  changeSidebarTLBgColor(color) {
    this.selectedBgColor = "";
    this.selectedTLBgColor = color;
    this.selectedTLBgImage = "";
    this.options.transparentColor = color;
    this.options.bgImageDisplay = false;

    //emit event to FUll Layout

    this.layoutService.emitCustomizerChange(this.options);
  }

  changeSidebarTLBgImage(url, color) {
    this.selectedTLBgColor = "";
    this.selectedTLBgImage = url;
    this.options.transparentColor = color;
    this.options.bgImageDisplay = false;

    //emit event to FUll Layout

    this.layoutService.emitCustomizerChange(this.options);
  }
}
