import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreDocument, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Observable, Subject } from 'rxjs';
import { ExamsInfo, ComplexityLevel, PSPName, StudyPlan, PSPDisplayRow, UserProfileInfo, TopicInfo, ResourceInfo } from './general';
import { AngularFireDatabase } from '@angular/fire/database';
import { CustomerPlanSetup, ResourceCompletion, StudyNotes, SharedStudyNotes, MailInfo } from './localBusinessClasses';
import { AngularFireFunctions } from '@angular/fire/functions';
import { first } from 'rxjs/operators';
import { AuthService } from '../auth/auth.service';

const TOPICS="topics";
const RESOURCETYPES="resourceTypes";
const TAGS="tags";
const PSPNAMES="pspNames";
const PSPLISTS="pspLists";
const CUSTPSPSCHED="deprecate_custPspNames";// deprecate
const CUSTPSPSCHEDULES="deprecate_custPspSchedules";// deprecate
const CUSTEXAMPLAN="custExamPlan";
const RESCOMPLSTATUS="resComplStatus";
const USERPROFILEINFO="userInfo";
const HIGHYIELD="highYieldSubTopics";
const RESOURCES="resources";
const PLANSETUP="planSetup";
const REVISIONNODE="revisionNode";
const PROBLEMNODE="reportedProblems";
const PRIVATESTUDYNOTES="privateStudyNotes";
const SHAREDSTUDYNOTES="sharedStudyNotes";
const FEEDBACKNODE="reportedFeedback";
const FEEDBACKELEMENTS="feedbackElements";
const GENERALFEEDBACKELEMENTS="generalfbElements";
const GENERALUSERINFOELEMENTS="generalUserInfoElements";
const EMAILNODE='mail';
const TRIAL="trial";

@Injectable({
  providedIn: 'root'
})
export class FirebaseHelperService {
  // following are copied from USMLEAdmin and must be visually verified
  exams: Array<Object> = [{id: "USMLE1", name: "USMLE Step 1"}, {id: "USMLE2", name: "USMLE Step 2 CK"}, {id: "USMLE3", name: "USMLE Step 2 CS"}];
  ex: Array<ExamsInfo> = [];
  complexityArray: Array<Object> = [{id: 1, name: "Low"}, {id: 2, name: "Medium"}, {id: 3, name: "High"}];
  ca: Array<ComplexityLevel> = [];
  userInfoObj: AngularFirestoreDocument<UserProfileInfo>;

  constructor(private afs: AngularFirestore, private db: AngularFireDatabase, private afFun: AngularFireFunctions) { 
    this.exams.forEach(e=>{
      // console.log("the obj is: ", e);
      this.ex.push(ExamsInfo.fromJson(Object(e)));
    });
    this.complexityArray.forEach(c=>{
      // console.log("the obj is: ", e);
      this.ca.push(ComplexityLevel.fromJson(Object(c)));
    });
  }

  getCreateID() {
    return this.afs.createId();
  }
  
  getUserProfileInfo(uid: string): Observable<UserProfileInfo> {
    this.userInfoObj = this.afs.doc<UserProfileInfo>(USERPROFILEINFO + "/" + uid);
    return this.userInfoObj.valueChanges();
  }

  updateUserProfileInfo(uid: string, upi: UserProfileInfo): Promise<any> {
    this.userInfoObj = this.afs.doc<UserProfileInfo>(USERPROFILEINFO + "/" + uid);
    // console.log(upi);
    // upi.json().catch((error: any) => console.log(error));
    // console.log(upi.toJson());
    return this.afs.collection(USERPROFILEINFO).doc(uid).set(upi.toJson());
  }

  getExamName(basedOnExamID: string): string {
    for(let e in this.ex) {
      if(this.ex[e].id == basedOnExamID) {
        return this.ex[e].name;
      }
    }
  }
  
  // getPSPName(id: string): Observable<PSPName> {
  //   let subObs = this.afs.doc<PSPName>(PSPNAMES + "/" + id);
  //   return subObs.valueChanges();
  // }
  
  getPSPStudyPlan(id: string) {
    let subObs = this.afs.doc<StudyPlan>(PSPLISTS + "/" + id);
    return subObs.valueChanges();
  }

  getSubjectList(examID: string) {
    return this.afs.collection("subjects", ref => ref.where('examID', '==', examID)).snapshotChanges();
  }
  getResourceTypesList() {
    return this.afs.collection(RESOURCETYPES).snapshotChanges();
  }
  getTagsList() {
    return this.afs.collection(TAGS).snapshotChanges();
  }
  getPSPNamesList(examID: string) {
    return this.afs.collection(PSPNAMES, ref => ref.where('examID', '==', examID).where('active', '==', true)).snapshotChanges();
  }
  getResourceList(topicID: string) {
    return this.afs.collection(RESOURCES, ref => ref.where('topicID', '==', topicID)).snapshotChanges();
  }
  getResourceList1(topicID: string, rts: string[]) {
    return this.afs.collection(RESOURCES, ref => ref.where('topicID', '==', topicID).where('sResourceTypeID', 'in', rts)).snapshotChanges();
  }

  getCustPSPSchedList(uid: string) {
    // return this.afs.collection(CUSTPSPSCHED).doc(uid).snapshotChanges();
    let node=CUSTPSPSCHED+"/"+uid;
    // console.log("node: ", node);
    let ref = this.db.list(node);
    return ref.valueChanges();
  }

  getCustPSPSchedName(uid: string, pspid: string) {
    let node=CUSTPSPSCHED+"/"+uid+"/"+pspid;
    let ref = this.db.object(node);
    return ref.valueChanges();
  }

  getcustPlanSetup(uid: string, examID: string) {
    let node=PLANSETUP+"/"+uid+"/"+examID;
    let ref = this.db.object(node);
    return ref.valueChanges();
  }

  saveCustPlantSetup(uid: string, examID: string, ps: CustomerPlanSetup) {
    let node=PLANSETUP+"/"+uid+"/"+examID;
    let ref = this.db.object(node);
    return ref.set(ps.toJson());
  }

  savePrivateNotes(uid: string, examID: string, notes: StudyNotes) {
    // console.log(notes);
    return this.afs.collection(PRIVATESTUDYNOTES).doc(examID).collection(uid).doc(notes.rid).set(notes.toJson());
  }

  getPrivateNotes(rid: string, examID: string, uid: string) {
    return this.afs.collection(PRIVATESTUDYNOTES).doc(examID).collection(uid).doc(rid).snapshotChanges();
  }

  getAllPrivateNotes(uid: string, examID: string) {
    return this.afs.collection(PRIVATESTUDYNOTES).doc(examID).collection(uid).snapshotChanges();
  }

  saveSharedNote(notes: SharedStudyNotes, examID: string) {
    let node=SHAREDSTUDYNOTES+"-"+examID;
    console.log(node);
    return this.afs.collection(node).doc(this.afs.createId()).set(notes.toJson());
  }

  getSharedNote(rid: string, examID: string, uid: string) {
    let node=SHAREDSTUDYNOTES+"-"+examID;
    // console.log(node);
    return this.afs.collection(node, ref=>ref.where('uid', '==', uid).where('rid', '==', rid)).snapshotChanges();
  }

  getAllSharedNotes(uid: string, examID: string) {
    let node=SHAREDSTUDYNOTES+"-"+examID;
    // console.log(node);
    return this.afs.collection(node, ref=>ref.where('uid', '==', uid)).snapshotChanges();
  }

  getGlobalNotes(rid: string, examID: string){
    let node=SHAREDSTUDYNOTES+"-"+examID;
    console.log(node);
    return this.afs.collection(node, ref=>ref.where('rid', '==', rid)).snapshotChanges();
  }

  deleteCustomerPSPStuff(uid: string, pspid: string) {
    let dataToDelete = {};
    dataToDelete[CUSTPSPSCHED+"/"+uid+"/"+pspid] = null;
    dataToDelete[CUSTPSPSCHEDULES+"/"+uid+"/"+pspid] = null;
    return this.firebaseUpdate(dataToDelete);
  }

  firebaseUpdate(dataToSave) {
    const subject = new Subject();
    this.db.database.ref().update(dataToSave)
      .then(
        val => {
        subject.next(val);
        subject.complete();
      },
      err => {
       subject.error(err);
       subject.complete();
      }
    );
    return subject.asObservable();
   }

  saveCustPSPSchedNameInfo(uid: string, psp: PSPName) {
    let node=CUSTPSPSCHED+"/"+uid+"/"+psp.id;
    let ref = this.db.object(node);
    return ref.set(psp);
  }
  // getPSPName(id: string): Observable<PSPName> {
  //   let subObs = this.afs.doc<PSPName>(PSPNAMES + "/" + id);
  //   return subObs.valueChanges();
  // }

  saveCustomerPSPSchedule(uid: string, pspid: string, psps: Map<string, PSPDisplayRow[]>) {
    let obj = [];
    let node=CUSTPSPSCHEDULES+"/"+uid+"/"+pspid;
    psps.forEach((value: PSPDisplayRow[], key: string) =>{
      let pp = [];
      value.forEach(v=>{
        pp.push(v);
      });
      obj[key]=pp;
    });
    // console.log("final object is: ", obj);
    let ref = this.db.object(node);
    return ref.set(obj);
  }
  
  getCustomerPSPSchedule(uid: string, pspid: string) {
    let node=CUSTPSPSCHEDULES+"/"+uid+"/"+pspid;
    let ref = this.db.list(node);
    return ref.snapshotChanges();
  }

  saveCustomerExamPSPSchedule(uid: string, examid: string, psps: Map<string, PSPDisplayRow[]>) {
    let obj = [];
    let node=CUSTEXAMPLAN+"/"+uid+"/"+examid;
    psps.forEach((value: PSPDisplayRow[], key: string) =>{
      let pp = [];
      value.forEach(v=>{
        pp.push(v);
      });
      obj[key]=pp;
    });
    // console.log("final object is: ", obj);
    let ref = this.db.object(node);
    return ref.set(obj);
  }

  getRevisionInfo(id: string) {
    return this.afs.collection(REVISIONNODE).doc(id).snapshotChanges();
  }

  saveCustomerExamPSPScheduleForDate(uid: string, examid: string, date: string, pp: PSPDisplayRow[]) {
    let node=CUSTEXAMPLAN+"/"+uid+"/"+examid+"/"+date;
    // console.log("node: ", node);
    let ref = this.db.object(node);
    // console.log(pp);
    return ref.set(pp);
  }

  saveEMailingRequestToSudeepAndPawan(subject: string, msg: string) {
    var obj: MailInfo=new MailInfo(subject, msg, 'info@bcss.us;usmlesarthi@gmail.com');
    return this.afs.collection(EMAILNODE).doc(this.afs.createId()).set(obj.toJson());
  }

  reportProblem(uid: string, dump: any) {
    // var obj: any = {};
    // obj[uid]=dump;
    // console.log(obj);
    return this.afs.collection(PROBLEMNODE).doc(this.afs.createId()).set(dump);
  }

  reportFeedback(uid: string, dump: any) {
    // var obj: any = {};
    // obj[uid]=dump;
    // console.log(obj);
    return this.afs.collection(FEEDBACKNODE).doc(this.afs.createId()).set(dump);
  }

  formatDate(dt: Date) {
    // var startDate = "Monday, January 9, 2010";
    var month_names_short = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];

    var convertedStartDate = dt;
    // var month = convertedStartDate.getMonth() + 1;
    var month = month_names_short[convertedStartDate.getMonth()];
    var day = convertedStartDate.getDate();
    var year = convertedStartDate.getFullYear();
    var shortStartDate = month + " " + day + ", " + year;    
    return shortStartDate;
  }

  getCustomerExamPSPSchedule(uid: string, examid: string) {
    let node=CUSTEXAMPLAN+"/"+uid+"/"+examid;
    // console.log(node);
    let ref = this.db.list(node);
    return ref.snapshotChanges();
  }

  getCustomerExamPSPScheduleForDate(uid: string, examid: string, date: string) {
    let node=CUSTEXAMPLAN+"/"+uid+"/"+examid+"/"+date;
    let ref = this.db.list(node);
    return ref.snapshotChanges();
  }

  saveResourceCompletionStatus(uid: string, examID: string, rc: ResourceCompletion) {
    let node=RESCOMPLSTATUS+"/"+uid+"/"+examID+"/"+rc.id;
    let ref = this.db.object(node);
    return ref.set(rc.toJson());
  }

  saveResourceCompletionStatusEmpty(uid: string, examID: string, id: string) {
    let node=RESCOMPLSTATUS+"/"+uid+"/"+examID+"/"+id;
    let ref = this.db.object(node);
    return ref.remove();
  }

  getCompletionStatus(uid: string, examID: string) {
    let node=RESCOMPLSTATUS+"/"+uid+"/"+examID;
    let ref = this.db.list(node);
    return ref.snapshotChanges();
  }

  getHighYieldSubTopics(topicID: string) {
    return this.afs.collection(HIGHYIELD, ref => ref.where('topicID', '==', topicID)).snapshotChanges();
  }

  getTopics() {
    return this.afs.collection(TOPICS, ref => ref.orderBy('orderNumber')).valueChanges();
  }

  getTopicList(subjectID: string) {
    return this.afs.collection(TOPICS, ref => ref.where('subjectID', '==', subjectID).orderBy('orderNumber')).snapshotChanges();
  }

  getResources() {
    return this.afs.collection<ResourceInfo>(RESOURCES).valueChanges();
  }
  getAllHighYieldSubTopics() {
    return this.afs.collection(HIGHYIELD).snapshotChanges();
  }
  public getComplexity(complexity: number): string {
    let retVal: string = ""
    for(let a of this.complexityArray) {
      if(a["id"] == complexity) {
        retVal = a["name"];
        // console.log(retVal);
        break;
      }
    }    
    return retVal;
  }

  getFeedbackElement(forRTID: string) {
    // console.log(forRTID);
    return this.afs.collection(FEEDBACKELEMENTS, ref=>ref.where('resourceTypeID', '==', forRTID).orderBy('sequence')).valueChanges();
  }

  getAllFeedbackElements() {
    return this.afs.collection(FEEDBACKELEMENTS, ref=>ref.where('resourceTypeID', '==', "fdsfsfd").orderBy('sequence')).valueChanges();
  }

  getGeneralFeedbackElementsList() {
    return this.afs.collection(GENERALFEEDBACKELEMENTS, ref => ref.orderBy('sequence')).valueChanges();
  }
  getUserInfoElementsList() {
    return this.afs.collection(GENERALUSERINFOELEMENTS, ref => ref.orderBy('sequence')).snapshotChanges();
  }

  // isTrialValid() {
  //   this.auth.user.getIdTokenResult(true).then((idTokenResult)=>{
  //     console.log("idTokenResult: ", idTokenResult);
  //     let tr = idTokenResult.claims.trial;
  //     if(tr != undefined) {
  //       let dt = new Date();
  //       if(tr > dt.getTime()/1000) {
  //         console.log("trial is still valid");
  //       } else {
  //         console.log("trial period has expired");
  //       }
  //     } else {
  //       console.log("No trial period used");
  //     }
  //   })
  // }

  // isSubscriptionValid() {
  //   this.auth.user.getIdTokenResult(true).then((idTokenResult)=>{
  //     console.log("idTokenResult: ", idTokenResult);
  //     let tr = idTokenResult.claims.subscription;
  //     if(tr != undefined) {
  //       let dt = new Date();
  //       if(tr > dt.getTime()/1000) {
  //         console.log("subscription is valid");
  //       } else {
  //         console.log("subscription period has expired");
  //       }
  //     } else {
  //       console.log("No subscription period used");
  //     }
  //   })
  // }

  readClaimsfor(email: string, role: string) {
    this.afFun.httpsCallable('getRoles')({email: email, role: role}).pipe(first())
    .subscribe(resp => {
      if(resp==null) {
        // this.assignedRolesByEMail[email+role]=false;
      } else {
        // this.assignedRolesByEMail[email+role]=resp;
      }
      // this.valRecd[email+role]=true;
      // console.log("this.assignedRolesByEMail: ", email+role, this.assignedRolesByEMail[email+role]);
      // console.log("this.assignedRolesByEMail: ", this.assignedRolesByEMail);
    }, err => {
      // this.valRecd[email+role]=true;
      console.log("no claims set priorly: ");
      // console.error( err );
    }); 
  }

  addDays(date: Date, days: number): Date {
    // console.log("date: ", date.getTime());
    var result = new Date(date);
    result.setDate(date.getDate() + days);
    // console.log("result: ", result.getTime());
    return result;
  }

  // beginPlannerTrial() {
  //   this.setClaimGenericDate(this.auth.user.email, TRIAL, this.addDays(new Date(), 7));
  // }

  setClaimGenericDate(email: string, role: string, dValue: Date) {
    // console.log("setClaimGenericDate()");
    var obj = {roles: []};
    var objVal:any = {};
    if(dValue!=undefined) {
      objVal[role] = dValue.getTime()/1000;
    } else {
      objVal[role] = dValue;
    }
    obj.roles.push(objVal);
    console.log(obj);

    this.afFun.httpsCallable('addTrial')({email: email, roles: objVal}).pipe(first())
    .subscribe(resp => {
      console.log(resp);
      this.readClaimsfor(email, role);
    }, err => {
      console.log("error was thrown: ");
      console.error( err );
    }); 
  }


}
