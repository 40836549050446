import { Injectable, NgZone } from '@angular/core';
import { Router } from '@angular/router';
import { auth } from  'firebase/app';
import { AngularFireAuth } from  "@angular/fire/auth";
import { User } from  'firebase';
import { AngularFirestoreDocument, AngularFirestore } from '@angular/fire/firestore';
import { UserProfileInfo, FeedbackElement } from '../services/general';
import { FirebaseHelperService } from '../services/firebase-helper.service';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  token: string;

  isAuthenticated() {
    // here you can check if user is authenticated or not through his token 
    // return this.isLoggedIn;
    return true;
  }
  user:  User = null;
  // userData: any; // Save logged in user data

  constructor(public  afAuth:  AngularFireAuth, public afs: AngularFirestore, public  router:  Router, 
          public ngZone: NgZone, public fhs: FirebaseHelperService) {
    this.afAuth.authState.subscribe(user => {
      if (user) {
        this.user = user;
        // console.log("setting user: ", this.user);
        localStorage.setItem('user', JSON.stringify(this.user));
      } else {
        // console.log("setting null user: ");
        localStorage.setItem('user', null);
      }
    })    
   }

  // Sign in with Facebook
  FacebookAuth() {
    return this.AuthLogin(new auth.FacebookAuthProvider());
  }  
  googleAuth() {
    return this.AuthLogin(new auth.GoogleAuthProvider());
  }  

  async SignUp(email: string, password: string, fname: string, lname: string, more: any, commaSeparatedFields: string) {
    // console.log(email, password);
    return this.afAuth.auth.createUserWithEmailAndPassword(email, password)
      .then((result) => {
        let obj = new UserProfileInfo(fname, lname, email, more, commaSeparatedFields);
        // console.log(obj.toJson());
        this.fhs.updateUserProfileInfo(result.user.uid, obj);
        this.SendVerificationMail();
        this.SetUserData(result.user);
        let msg = email + ' ' + fname + ' ' + lname + ' has joined';
        this.fhs.saveEMailingRequestToSudeepAndPawan("new signup", msg);
        this.ngZone.run(() => {
          this.router.navigate(['content-layout/verifyemail']);
        });
        // window.alert("Please verify your email address")
      }).catch((error) => {
        window.alert(error.message);
      })
  }

  async SetUserData(user: User) {
    const userRef: AngularFirestoreDocument<any> = this.afs.doc(`users/${user.uid}`);
    this.user = user;
    // const userData: User = {
    //   uid: user.uid,
    //   email: user.email,
    //   displayName: user.displayName,
    //   photoURL: user.photoURL,
    //   emailVerified: user.emailVerified
    // }
    // return userRef.set(this.user, {
    //   merge: true
    // })
    // return userRef.set(userData, {
    //   merge: true
    // })
  }
  async SendVerificationMail() {
    return this.afAuth.auth.currentUser.sendEmailVerification()
    .then(() => {
      //this.router.navigate(['content-layout/login']);
    })
  }  

  sendVerifyEMail() {
    // console.log("sendVerifyEMail");
    return this.afAuth.auth.currentUser.sendEmailVerification().then(() => {
      // console.log("sendVerifyEMail1");
      this.ngZone.run(() => {
        // console.log("sendVerifyEMail2");
        window.alert("Verification email sent.");
        this.router.navigate(['content-layout/login']);
      });
    });
  }  

  // Auth logic to run auth providers
  async AuthLogin(provider) {
    return this.afAuth.auth.signInWithPopup(provider)
    .then((result) => {
        console.log('You have been successfully logged in!')
    }).catch((error) => {
        console.log(error)
    })
  }

   async  login(email:  string, password:  string) {
      try {
          await  this.afAuth.auth.signInWithEmailAndPassword(email, password)
          this.router.navigate(['full-layout']);
      } catch (e) {
          alert("Error!"  +  e.message);
      }
    }
    loginWithVerify(email:  string, password:  string) {
      try {
          this.afAuth.auth.signInWithEmailAndPassword(email, password).then((result) => {
            if (result.user.emailVerified != true) {
              this.SendVerificationMail();
              // localStorage.removeItem('user');
              window.alert('Please validate your email address. Kindly check your inbox.');
            } else {
              // console.log("verified");
              this.ngZone.run(() => {
                this.router.navigate(['full-layout']);
              });
            }
          }).catch((error)=>{
            alert("Error: "  +  error.message);  
          });
      } catch (e) {
          alert("Error!"  +  e.message);
      }
      }

      async logout(){
      await this.afAuth.auth.signOut().then((r)=>{});
      localStorage.removeItem('user');
      // console.log("logout");
      this.router.navigate(['content-layout/login']);
  }
  get isLoggedIn(): boolean {
    const  user  =  JSON.parse(localStorage.getItem('user'));
    return  user  !==  null;
}  
// get isLoggedIn(): boolean {
//   const user = JSON.parse(localStorage.getItem('user'));
//   return (user !== null && user.emailVerified !== false) ? true : false;
// }
    // Reset Forggot password
    async ForgotPassword(passwordResetEmail: string) {
      return this.afAuth.auth.sendPasswordResetEmail(passwordResetEmail)
      .then(() => {
        window.alert('Password reset email sent, check your inbox.');
        this.router.navigate(['login']);
      }).catch((error) => {
        window.alert(error)
      })
    }
}
