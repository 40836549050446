// "bash copyGeneral.sh" from current directory
export class AdminProfileInfo {
    constructor(
    public uid: string,
    public email: string) {}
    
    static fromJson({uid, email}) {
        return new AdminProfileInfo(uid, email);
    }

    toJson(): any {
        return Object.assign({uid: this.uid}, {email: this.email});
    }
}

export class UserProfileInfo {
    constructor(
    public fname: string,
    public lname: string,
    public email: string,
    public more: any,
    public commaSeparatedMoreFields: string) {}
    
    static fromJson({fname, lname, email, more, commaSeparatedMoreFields}) {
        // let fields = commaSeparatedMoreFields.split(",");
        // let obj={};
        // fields.forEach(f=>{
        //     obj[f]=more[f];
        // });
        return new UserProfileInfo(fname, lname, email, more, commaSeparatedMoreFields);
    }

    toJson(): any {
        let obj=Object.assign({fname: this.fname}, {lname: this.lname}, {email: this.email}, 
            {commaSeparatedMoreFields: this.commaSeparatedMoreFields});
    let fields = this.commaSeparatedMoreFields.split(",");
        let more={};
        fields.forEach(f=>{
            let v=this.more[f];
            if(v!=null) {
                more[f]=v;
            }
        });
        obj["more"]=more;
        // return Object.assign({fname: this.fname}, {lname: this.lname}, {email: this.email});
        return obj;
    }
}

export class SubjectInfo {
    constructor(
        public id: string,
        public sName: string,
        public createdBy: string,
        public examID: string
    ) {}

    static fromJson({id, sName, createdBy, examID}) {
        return new SubjectInfo(id, sName, createdBy, examID);
    }

    toJson(): any {
        return Object.assign({id: this.id}, {sName: this.sName}, {createdBy: this.createdBy}, {examID: this.examID});
    }
}

export class TopicInfo {
    constructor(
        public id: string,
        public sName: string,
        public createdBy: string,
        public subjectID: string,
        public orderNumber: number,
        public tags: string[],
        public complexity: number
    ) {}

    static fromJson({id, sName, createdBy, subjectID, orderNumber, tags, complexity}) {
        var c=complexity;
        if(c==undefined) {
            c=1;
            console.log(complexity, c);
        }
        return new TopicInfo(id, sName, createdBy, subjectID, orderNumber, tags, c);
    }

    static fromJsonArray(json : any[]) : TopicInfo[] {
        return json.map(TopicInfo.fromJson);
    }

    toJson(): any {
        // return Object.assign({sName: this.sName}, {createdBy: this.createdBy}, {subjectID: this.subjectID}, {orderNumber: this.orderNumber});
        return Object.assign({id: this.id}, {sName: this.sName}, {createdBy: this.createdBy}, {subjectID: this.subjectID}, 
            {orderNumber: this.orderNumber}, {tags: this.tags}, {complexity: this.complexity});
    }
}

export class ExamsInfo {
    constructor(public id: string, public name: string){}
    static fromJson({id, name}) {
        return new ExamsInfo(id, name);
    }
}

export class ComplexityLevel {
    constructor(public id: number, public name: string){}
    static fromJson({id, name}) {
        return new ComplexityLevel(id, name);
    }
}

export class Tag {
    constructor(public id: string, public name: string){}
    static fromJson({id, name}) {
        return new Tag(id, name);
    }
    toJson(): any {
        return Object.assign({id: this.id}, {name: this.name});
    }    
}

export class Assessment {
    constructor(public id: string, public title: string, public questionCount: number){}
    static fromJson({id, title, questionCount}) {
        return new Assessment(id, title, questionCount);
    }
    static fromJsonArray(json : any[]) : Assessment[] {
        return json.map(Assessment.fromJson);
    }
    toJson(): any {
        return Object.assign({id: this.id}, {title: this.title}, {questionCount: this.questionCount});
    }    
}

export class AssessmentsPerTopic {
    constructor(public assessmentIDs: string, public topicID: string){
    }
    static fromJson({assessmentIDs, topicID}) {
        return new AssessmentsPerTopic(assessmentIDs, topicID);
    }
    static fromJsonArray(json : any[]) : AssessmentsPerTopic[] {
        return json.map(AssessmentsPerTopic.fromJson);
    }
    toJson(): any {
        return Object.assign({topicID: this.topicID}, {assessmentIDs: this.assessmentIDs});
    }    
}

export class PSPName {
    constructor(public id: string, public name: string, public examID: string, public desc: string, public active: Boolean){}
    static fromJson({id, name, examID, desc, active}) {
        return new PSPName(id, name, examID, desc, active);
    }

    static fromJsonArray(json : any[]) : PSPName[] {
        return json.map(PSPName.fromJson);
    }

    toJson(): any {
        return Object.assign({id: this.id}, {name: this.name}, {examID: this.examID}, {desc: this.desc}, {active: this.active});
    }    
}

export class StudyPlan {
    constructor(public id: string, public episodes: PSPDisplayRow[]) {}
    static fromJson({id, episodes}) {
        return new StudyPlan(id, PSPDisplayRow.fromJsonArray(episodes));
    }
    toJson(): any {
        let objects = [];
        this.episodes.forEach(item => objects.push(item.toJson()));
        objects = this.episodes.map((obj)=> {return Object.assign({}, obj)});
        return Object.assign({id: this.id}, {episodes: objects})
    }
}

export class ResourceType {
    constructor(public id: string, public sType: string, public sAttributes: string){}
    static fromJson({id, sType, sAttributes}) {
        return new ResourceType(id, sType, sAttributes);
    }
    toJson(): any {
        return Object.assign({id: this.id}, {sType: this.sType}, {sAttributes: this.sAttributes});
    }
    static fromJsonArray(json : any[]) : ResourceType[] {
        return json.map(ResourceType.fromJson);
    }
}

export class ResourceInfo {
    constructor(
        public id: string,
        public topicID: string,
        public sResourceTypeID: string,
        public sTitle: string,
        public sDispTitle: string,
        public sDescription: string,
        public sResourceQualifier: string,
        public duration: number,
        public complexity: number,
        public createdBy: string,
        public tags: string[]
    ) {}

    static fromJson({id, topicID, sResourceTypeID, sTitle, sDispTitle, sDescription, sResourceQualifier, duration, complexity, createdBy, tags}) {
        return new ResourceInfo(id, topicID, sResourceTypeID, sTitle, sDispTitle, sDescription, sResourceQualifier, duration, complexity, createdBy, tags);
    }

    static fromJsonArray(json : any[]) : ResourceInfo[] {
        return json.map(ResourceInfo.fromJson);
    }

    toJson(): any {
        return Object.assign({id: this.id}, {topicID: this.topicID}, {sResourceTypeID: this.sResourceTypeID}, {sTitle: this.sTitle}, {sDispTitle: this.sDispTitle},
            {sDescription: this.sDescription }, {sResourceQualifier: this.sResourceQualifier}, 
            {duration: this.duration}, {complexity: this.complexity}, {createdBy: this.createdBy}, {tags: this.tags} );
    }
}

export class HighYieldTopicInfo {
    constructor(
        public id: string,
        public topicID: string,
        public sTitle: string,
        public sDescription: string,
        public createdBy: string
    ) {}

    static fromJson({id, topicID, sTitle, sDescription, createdBy}) {
        return new HighYieldTopicInfo(id, topicID, sTitle, sDescription, createdBy);
    }

    static fromJsonArray(json : any[]) : HighYieldTopicInfo[] {
        return json.map(HighYieldTopicInfo.fromJson);
    }

    toJson(): any {
        return Object.assign({id: this.id }, {topicID: this.topicID }, {sTitle: this.sTitle }, {sDescription: this.sDescription },
                {createdBy: this.createdBy});
    }
}

export class BannerInfo {
    constructor(
        public id: string,
        public sTitle: string,
    ) {}

    static fromJson({id, sTitle}) {
        return new BannerInfo(id, sTitle);
    }

    static fromJsonArray(json : any[]) : BannerInfo[] {
        return json.map(BannerInfo.fromJson);
    }

    toJson(): any {
        return Object.assign({id: this.id }, {sTitle: this.sTitle });
    }
}

export class HotlistInfo {
    constructor(
        public id: string,
        public sTitle: string,
        public sList: string
    ) {}

    static fromJson({id, sTitle, sList}) {
        return new HotlistInfo(id, sTitle, sList);
    }

    static fromJsonArray(json : any[]) : HotlistInfo[] {
        return json.map(HotlistInfo.fromJson);
    }

    toJson(): any {
        return Object.assign({id: this.id }, {sTitle: this.sTitle }, {sList: this.sList});
    }
}

export class PSPDisplayRow {
    constructor(
        public resourceTitle: string,
        public resourceDispTitle: string,
        public subjectName: string,
        public topicName: string,
        public resourceType: string,
        public resourceDesc: string,
        public complexity: string,
        public tags: string,
        public skip: boolean,
        public resourceID: string,
        public topicID: string,
        public duration: number,
        public assignedDuration: number=0,
        public resourceIDIsActuallyAssessmentID?: boolean,
    ){}

    static fromJson({resourceTitle, resourceDispTitle, subjectName, topicName, resourceType, resourceDesc, complexity, tags, skip, resourceID, topicID, duration, assignedDuration, resourceIDIsActuallyAssessmentID}) {
        let flag = false;
        if(resourceIDIsActuallyAssessmentID!=undefined) {
            flag = resourceIDIsActuallyAssessmentID;// in this case, the resourceDesc is the assessment description
        }
        return new PSPDisplayRow(resourceTitle, resourceDispTitle, subjectName, topicName, resourceType, resourceDesc, complexity, tags, skip, resourceID, topicID, duration, assignedDuration, flag);
    }

    static fromJsonArray(json : any[]) : PSPDisplayRow[] {
        return json.map(PSPDisplayRow.fromJson);
    }

    toJson(): any {
        let flag = false;
        if(this.resourceIDIsActuallyAssessmentID!=undefined) {
            flag = this.resourceIDIsActuallyAssessmentID;
        }
        return Object.assign({resourceTitle: this.resourceTitle}, {resourceDispTitle: this.resourceDispTitle}, {subjectName: this.subjectName}, {topicName: this.topicName}, {resourceType: this.resourceType},
            {resourceDesc: this.resourceDesc}, {complexity: this.complexity}, {tags: this.tags}, {skip: this.skip}, 
                {resourceID: this.resourceID}, {topicID: this.topicID}, {duration: this.duration}, {assignedDuration: this.assignedDuration}, {resourceIDIsActuallyAssessmentID: flag});
    }
}

export class StudyRevision {
    constructor(
        public resTypes: string,
        public factor: number
    ) {}

    static fromJson({resTypes, factor}) {
        return new StudyRevision(resTypes, factor);
    }

    static fromJsonArray(json : any[]) : StudyRevision[] {
        return json.map(StudyRevision.fromJson);
    }

    toJson(): any {
        return Object.assign({resTypes: this.resTypes}, {factor: this.factor});
    }
}

export const feedbackTypes=["Single-Value", "Multiple-Values", "Freeform-Text", "Rating-Star-1-5", "Number-Input"];
export const UserInfoTypes=["Freeform-Text", "Number-Input"];
export class FeedbackElement {
    constructor(
        public id: string,
        public resourceTypeID: string,
        public label: string,
        public feedbackType: number,
        public commaSeparatedQualifiers: string,
        public sequence: number
    ) {}

    static fromJson({id, resourceTypeID, label, feedbackType, commaSeparatedQualifiers, sequence}) {
        return new FeedbackElement(id, resourceTypeID, label, feedbackType, commaSeparatedQualifiers, sequence);
    }

    static fromJsonArray(json : any[]) : FeedbackElement[] {
        return json.map(FeedbackElement.fromJson);
    }

    toJson(): any {
        return Object.assign({id: this.id}, {resourceTypeID: this.resourceTypeID}, {label: this.label}, {feedbackType: this.feedbackType}, 
            {commaSeparatedQualifiers: this.commaSeparatedQualifiers}, {sequence: this.sequence});
    }
}

