import { Routes, RouterModule } from '@angular/router';

//Route for content layout with sidebar, navbar and footer
export const Full_ROUTES: Routes = [
  {
    path: 'changelog',
    loadChildren: () => import('../../changelog/changelog.module').then(m => m.ChangeLogModule)
  },
  {
    path: 'planner',
    loadChildren: () => import('../../student-planner/student-planner.module').then(m => m.StudentPlannerModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('../../user-profile/user-profile.module').then(m => m.UserProfileModule)
  },
  {
    path: 'billing',
    loadChildren: () => import('../../stripe-page/stripe-page.module').then(m => m.StripePageModule)
  },
  {
    path: 'assumptions',
    loadChildren: () => import('../../user-profile/user-profile.module').then(m => m.UserProfileModule)
  },
  {
    path: 'addnewpsp',
    loadChildren: () => import('../../add-new-psp/add-new-psp.module').then(m => m.AddNewPspModule)
  },
  {
    path: 'createcustom',
    loadChildren: () => import('../../create-custom/create-custom.module').then(m => m.CreateCustomModule)
  },
  {
    path: 'examstudyplan/:id',
    loadChildren: () => import('../../exam-study-plan/exam-study-plan.module').then(m => m.ExamStudyPlanModule)
  },
  {
    path: 'flashcards',
    loadChildren: () => import('../../flash-cards/flash-cards.module').then(m => m.FlashCardsModule)
  },
  {
    path: 'mycalendar',
    loadChildren: () => import('../../my-calendar/my-calendar.module').then(m => m.MyCalendarModule)
  },
  {
    path: 'planview/:id',
    loadChildren: () => import('../../plan-view/plan-view.module').then(m => m.PlanViewModule)
  },
  {
    path: 'full-layout',
    loadChildren: () => import('../../pages/full-layout-page/full-pages.module').then(m => m.FullPagesModule)
  }
];
